import React, { useEffect, useState } from 'react'
import './landingPage.css'
import FGMembersCard from '../../../../components/cards/id/fg-members-card'
import axios from 'axios'
import { Image } from 'react-bootstrap'
import FooterEn from '../../../../components/footer/en/footer'

const api = axios.create({
  baseURL: `https://cmsfg.fitekservice.my.id/api`
})

const LandingPageEn = () => {

  const [result, setResult] = useState([]);
  const [partner, setPartner] = useState([]);
  const [contactUs, setContactUs] = useState([]);

  useEffect(() => {
    // Fungsi untuk memanggil API
    const fetchData = async () => {
      try {
        const response = await api.get('/banners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=FG'); // Ganti '/endpoint' dengan path API yang sesuai
        setResult(response.data.data); // Mengatur hasil response ke state
        console.log(response.data);

        const partnerResponse = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity'); // Ganti '/endpoint' dengan path API yang sesuai
        setPartner(partnerResponse.data.data);
        console.log(partnerResponse.data);


        const contactUsResponse = await api.get('/contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity'); // Ganti '/endpoint' dengan path API yang sesuai
        setContactUs(contactUsResponse.data.data);
        console.log(contactUsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Panggil fungsi fetch saat komponen di-mount
    fetchData();
  }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount


  return (
    <scroll>
      <div className='banner-fg-div' id='home'>
        {/* <img src='/assets/banner-fg.png' alt='Banner FG' width={1500} height={550} /> */}
        <Image src={`https://cmsfg.fitekservice.my.id/assets/images/banner/${result.file}`} alt='Banner FG' className='img-banner' fluid />
        {/* <div className='banner-fg-text'>
          <h1 className='poppins-bold'>
          Together with <b>Fhadira</b>
          <br /><b>Group</b> Building
          <br />The Nation

          </h1>
        </div> */}
      </div>
      <div className='our-members-title'>
        <h1 className='poppins-bold'>
          Our Members
        </h1>
      </div>
      <div className='grid-container'>
        {
          partner.map(partner => (
            <FGMembersCard
              imageSrc={`https://cmsfg.fitekservice.my.id/assets/images/partner/${partner.file}`}
              title={`${partner.name}`}
              text={`${partner.about}`}
              partnerName={`${partner.name}`}
              className='grid-item'
            />
          ))
        }
      </div>
      {/* <div className='grid-container'>
        <FGMembersCard
          imageSrc='/assets/logo-fitek.png'
          title='PT. Fhadira Inovasi Teknologi'
          text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, nisi molestiae eaque perferendis est dicta incidunt autem asperiores maxime harum consequatur dignissimos ipsum, sint molestias voluptate pariatur repudiandae vel facere?'
          className='grid-item'
        />
        <FGMembersCard
          imageSrc='/assets/logo-fitek.png'
          title='PT. Fhadira Inovasi Teknologi'
          text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, nisi molestiae eaque perferendis est dicta incidunt autem asperiores maxime harum consequatur dignissimos ipsum, sint molestias voluptate pariatur repudiandae vel facere?'
          className='grid-item'
        />
        <FGMembersCard
          imageSrc='/assets/logo-fitek.png'
          title='PT. Fhadira Inovasi Teknologi'
          text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, nisi molestiae eaque perferendis est dicta incidunt autem asperiores maxime harum consequatur dignissimos ipsum, sint molestias voluptate pariatur repudiandae vel facere?'
          className='grid-item'
        />
        <FGMembersCard
          imageSrc='/assets/logo-fitek.png'
          title='PT. Fhadira Inovasi Teknologi'
          text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, nisi molestiae eaque perferendis est dicta incidunt autem asperiores maxime harum consequatur dignissimos ipsum, sint molestias voluptate pariatur repudiandae vel facere?'
          className='grid-item'
        />
        <FGMembersCard
          imageSrc='/assets/logo-fitek.png'
          title='PT. Fhadira Inovasi Teknologi'
          text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, nisi molestiae eaque perferendis est dicta incidunt autem asperiores maxime harum consequatur dignissimos ipsum, sint molestias voluptate pariatur repudiandae vel facere?'
          className='grid-item'
        />
      </div> */}
      <FooterEn phone={contactUs.mobile_phone} email={contactUs.email} address={contactUs.address} />
    </scroll>
  )
}

export default LandingPageEn
