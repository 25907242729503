import React from 'react';
import { Card } from 'react-bootstrap';
import './career-card.css';
import { Link } from 'react-router-dom';

const CareerCard = ({title, subtitle, text, partnerName }) => {
  return (
    <Card border='light' className="career-card">
      <Card.Body>
        <Card.Title className='career-card-title poppins-bold'>
          {title}
        </Card.Title>
        <Card.Subtitle className='career-card-subtitle poppins-medium'>{subtitle}</Card.Subtitle>
        <div className='divider'/>
        <Card.Text className='career-card-text poppins-medium'>
          {text}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CareerCard;